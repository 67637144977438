@import '@assets/scss/bootstrap/variables';

.souscriptions-stepper {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  &__content {
    max-width: 100%;
    padding-top: 5rem;
  }

  &__header {
    max-width: 100% !important;
    margin-bottom: 1.5rem !important;
  }

  &__step-bar {
    width: 100%;
    position: relative;
    margin-bottom: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      background-color: map-get($custom-colors, basic);
      border: 2px solid map-get($custom-colors, basic);
      width: 0%;
      z-index: -1;
    }

    &.progress--25 {
      &::before {
        width: 25%;
      }
    }

    &.progress--50 {
      &::before {
        width: 50%;
      }
    }

    &.progress--75 {
      &::before {
        width: 75%;
      }
    }

    &.progress--100 {
      &::before {
        width: 100%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      background-color: #b2c1cb;
      border: 2px solid #b2c1cb;
      width: 100%;
      z-index: -2;
    }
  }

  &__step {
    min-width: 29%;
    font-weight: 700;
    color: map-get($custom-colors, extra);
    padding: 0.6rem;
    text-align: center;
    border-radius: 2rem;
    background: transparent;
    position: relative;
    overflow: hidden;

    &.pointer {
      cursor: pointer;
    }

    > span {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: #b2c1cb;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }

    &--dot {
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid map-get($custom-colors, basic);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.6rem;

      .dot {
        width: 0.7rem;
        height: 0.7rem;
        background-color: map-get($custom-colors, basic);
        border-radius: 50%;
      }
    }

    &.active {
      background-color: #fff;

      &::after {
        content: '';
        position: absolute;
        background-color: map-get($custom-colors, basic);
        height: 100%;
        width: 0%;
        left: 0;
        top: 0;
        z-index: 1;
        border-bottom-left-radius: 2rem;
        border-top-left-radius: 2rem;
      }

      &--25 {
        &::after {
          width: 25%;
        }
      }

      &--50 {
        &::after {
          width: 48%;
        }
      }

      &--75 {
        &::after {
          width: 75%;
        }
      }

      &--100 {
        color: #fff;

        &::after {
          width: 100%;
        }
      }
    }
  }
}
