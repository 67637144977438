.entites-list__container {
  padding-top: 5rem;
  max-width: 90%;

  .card {
    height: 100%;
    overflow: hidden;

    .card-body {
      overflow: auto;
    }
  }

  th,
  td {
    text-align: left !important;
  }
}

.title {
  position: relative;
}

.refresh__btn {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.load {
  &--primary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  &--rote {
    animation: load 1s infinite linear;
  }
  &__container {
    position: relative;
    min-height: 100vh;
  }
}

.entites-list__table > :not(caption) > * > * {
  border: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .entite-details__container {
    .card {
      .card-body {
        padding: 1rem 2rem !important;
      }
    }
  }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .entites-list__container {
    .card {
      .card-body {
        padding: 1.5rem 2.5rem !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .entites-list__container {
    .card {
      .card-body {
        padding: 2.5rem 5rem !important;
      }
    }
  }
}

.table__td {
  width: 340px !important;
  &--1 {
    width: 340px !important;
  }
  &--2 {
    width: 130px !important;
  }
  &--3 {
    width: 140px !important;
  }
  &--4 {
    width: 150px !important;
  }
}

.tr-ligne {
  &__icon {
    // transform: scale(0) !important;
    opacity: 0 !important;
    // transition: .2s transform ease-in-out , .3s opacity ease-in-out .1s;
    // transition: opacity .3s ease-in-out !important;
  }
  &:hover {
    .tr-ligne__icon {
      // transform: scale(1) !important;
      opacity: 1 !important;
    }
  }
}
