.kyc-folder-document-item {
  &__title {
    &.half-w {
      max-width: 45%;
    }

    &.full-w {
      flex: 1 1 auto;
    }
  }

  &__normal {
    flex: 0 1 45%;
  }

  &__button {
    flex: 0 1 45%;
    & > .upload-document-btn {
      width: 100%;
      padding: 0.5rem;
      border: 1px dashed var(--bs-extra);
      border-radius: 0.2rem;
      cursor: pointer;

      &:hover {
        color: white !important;
      }
    }
  }
}

.op-7 {
  opacity: 0.7;
}
