.souscriptions-list__container {
  padding-top: 5rem;
  .card {
    height: 100%;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.souscriptions-list__init,
.souscriptions-list__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.load {
  &--primary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  &--rote {
    animation: load 1s infinite linear;
  }
  &__container {
    position: relative;
    min-height: 100vh;
  }
}

@keyframes load {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.principalDiv {
  position: relative;
}
