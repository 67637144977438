@import "@assets/scss/bootstrap/variables";

.header-secondary__nav__languages__item {

    cursor: pointer;
    padding: 0.5rem;
    border-radius: 10px;
    background-color: var(--bs-dark);

    &__name {
        color: var(--bs-light);
    }

    &:hover {
        background-color: var(--bs-primary);
        border: 1px solid var(--bs-primary);

        .header-secondary__nav__languages__item__name {
            color: var(--bs-dark);
            font-weight: bold;
        }
    }
}

.header-secondary__nav__languages__item__selected {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 10px;
    border: 2px solid var(--bs-dark);
    background-color: var(--bs-info);

    &:hover {
        background-color: var(--bs-primary);
        border: 1px solid var(--bs-primary);
    }

    &__name {
        color: var(--bs-dark);
        font-weight: bold;
    }

}

.language-dropdown {
    &__toggle {
        padding: 0;
        border-radius: 20px !important;
        background-color: var(--bs-light);
        border: 2px solid var(--bs-accent);
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 2rem;
    }

    &__menu {
        border-radius: 5px !important;
        background-color: var(--bs-light);
        border: 1px solid var(--bs-accent);
        max-width: 5em !important;

        &__wrapper {
            max-width: 5em !important;
            background-color: var(--bs-light);
            border-radius: 5px !important;
        }
    }
}

.dropdown-menu {
    max-width: 2rem;
}