.souscription-item {
    border-radius: 0.8rem;
    padding: 0.6rem;
    margin-bottom: 0.6rem !important;
}

.souscription-item__progress {
    height: 0.6rem !important;
}

.souscription-item__details {
    font-size: 0.75rem;
}

.souscription-item__title {
    font-size: 0.9rem;
}

@media (max-width: 1199.98px) {
    .souscription-item {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .souscription-item {
        max-width: 63rem !important;
    }
}
