.form-input__icon {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
}

.form-input__control {
    padding-right: 2.5rem !important;
}

.form-input__control__sm {
    padding: .25rem .5rem !important;
}

.form-input__control__password {
    background-image: none !important;
}

.border-none {
    border-width: 0px !important;
}

.is-invalid {
    border-width: 2px !important;
}
