.mt-10{
    margin-top: 4rem;
}

button#btn-modal{
    padding: 0 !important;
    text-decoration: none !important;
    color: #012F54;
    text-transform: lowercase !important;
    &:hover{
        text-decoration: none !important;
    }
}

button {
    &:hover{
        text-decoration: none !important;
    }
}
.profile__container {
    padding-top: 5rem;
    min-height: 80vh;
}

.profile__form__change-password-link:hover {
    text-decoration: underline;
}

.profile__form__cni__container {
    height: 21vh;
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
}

.profile__form__cni__box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile__form__cni__box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.profile__form__cni__box::before {
    content: "";
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-extra);
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #142f542b;
    transition: all 0.15s linear;
}

.profile__form__cni__box.card::before {
    font-family: "Font Awesome 5 Free";
    content: "\f2bb";
    font-weight: 900;
}

.profile__form__cni__box.done::before {
    font-family: "Font Awesome 5 Free";
    content: "\f058";
    font-weight: 900;
}

.profile__form__cni__box.upload::before {
    font-family: "Font Awesome 5 Free";
    content: "\f382";
    font-weight: 900;
}

.profile__form__cni__box:hover::before {
    background-color: #142f5448;
}

.form{
    &__field{
        position: relative;
    }
    &__icon{
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .profile__container {
        .card {
            .card-body {
                padding: 3rem 4rem;
            }
        }
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .profile__container {
        .card {
            .card-body {
                padding: 3rem 5rem;
            }
        }
    }
}

@media (min-width: 1200px) {
    .profile__container {
        .card {
            .card-body {
                padding: 4rem 10rem;
            }
        }
    }
}


select{
    &.dropdown-none{
        /* for Firefox */
        -moz-appearance: none;
        /* for Safari, Chrome, Opera */
        -webkit-appearance: none;
    }
}