@import "@assets/scss/bootstrap/variables";

.souscriptions-initial {
    &__content {
        padding-top: 5rem;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }

    .card {
        min-height: 50vh;
    }

    &__box {
        padding: 0 5rem;
        padding-top: 1rem;
    }

    &__title {
        font-weight: 700;
        font-size: .9rem;
        text-align: left;
        margin-bottom: 2rem;
        width: 100% !important;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 3rem;
            height: 1.1px;
            background-color: darken($color: #eee, $amount: 10);
            top: 150%;
        }
    }

    &__items {
        width: 100%;
        margin: 0 auto;

        label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;

            .square {
                width: 20px;
                height: 20px;
                border: 1px #ccc solid;
                border-radius: .3rem;
                margin-right: 1rem;
            }


        }

        &-grid {
            margin: 0 auto;
            padding: 2rem 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 2rem;
            grid-column-gap: 2rem;
        }
    }

    &__item {
        cursor: pointer;

        input:checked~label .square {
            background-color: #08ffa0 !important;
            border-color: #08ffa0;
        }
    }

    &__buttons {
        width: 100%;
        flex: 0 1 auto;
    }
}

@media (max-width: 768px) {
    .souscriptions-initial {
        .card {
            &-body {
                padding: 1rem 2rem !important;
            }

            &__title {
                width: 100%;
            }
        }

        &__grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .souscriptions-initial {
        .card {
            &-body {
                padding: 1rem 2rem !important;
            }
        }

        &__title {
            width: 100%;
        }

        &__grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .souscriptions-initial {
        .card {
            &-body {
                padding: 1.5rem 5rem !important;
            }
        }

        &__title {
            width: 100%;
        }
    }
}

@media (min-width: 1200px) {
    .souscriptions-initial {
        .card {
            &-body {
                padding: 2.5rem 10rem !important;
            }
        }

        &__title {
            width: 70%;
        }
    }
}