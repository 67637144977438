@import '@assets/scss/bootstrap/variables';

.souscriptions-stepper-dossier {
  &__wrapper {
    display: flex;
    position: relative;
  }

  &__popover {
    position: absolute;
    bottom: 3rem;
    left: 1rem;
    z-index: 2;
  }

  .table > :not(caption) > * > * {
    border: none;
  }

  &__sidebar {
    width: 240px !important;
    background-color: var(--bs-dark);
    color: #fff;
    padding: 0 1rem 1rem 1.6rem;
    display: flex;
    flex-direction: column;

    &__wrapper {
      flex: 1 1 auto;
      padding: 1rem 0rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .stepper {
        &__item-box {
          display: flex;
          flex-direction: column;
        }

        &__step {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

          &__icon {
            width: 1.8rem !important;
            height: 1.8rem !important;
            background-color: #fff;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0.5rem 0;
            opacity: 0.4;

            * {
              color: gray;
            }

            &.active {
              opacity: 1 !important;

              * {
                color: #249999 !important;
              }
            }

            &.complete {
              opacity: 1 !important;
              background-color: #249999 !important;

              * {
                color: var(--bs-dark);
              }
            }
          }

          &__label {
            font-weight: 700;
            opacity: 0.4;

            &.active {
              opacity: 1 !important;
              color: #fff !important;
            }

            &.complete {
              opacity: 1 !important;
              color: #249999 !important;
            }
          }
        }

        &__progress {
          .progress {
            height: 4rem;
            background-color: #e9ecef;
            border-radius: 0.25rem;
            width: 2.5%;
            margin-left: 0.78rem;
            opacity: 0.4;
            flex: none;

            &--50 {
              position: relative;
              background-color: #fff;
              opacity: 1 !important;

              &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 50%;
                background-color: #249999;
                top: 0;
                left: 0;
                right: 0;
              }
            }

            &--100 {
              background-color: #249999;
              opacity: 1 !important;
            }
          }
        }
      }

      .doc {
        background-color: #269999 !important;
        padding: 0.7rem 0.8rem;
        border-radius: 0.5rem;
        cursor: pointer;

        .doc-icon {
          color: #fff !important;
        }
      }
    }

    .btns {
      flex: 0 1 auto;
      display: flex;
      justify-content: space-between;

      button {
        outline: none;
        border: none;
        background-color: transparent;
        color: #fff;
        padding: 0.2rem 0.6rem;
        border-radius: 0.3rem;

        &:first-child {
          margin-right: 1rem;
          border: 1.2px solid #fff;

          &.disabled {
            pointer-events: none !important;
            cursor: none !important;
          }
        }

        &:last-child {
          border: 1.2px solid #fff;

          &.disabled {
            pointer-events: none !important;
            cursor: none !important;
          }
        }
      }
    }
  }

  &--lg {
    height: 500px !important;

    .souscriptions-stepper-dossier__sidebar {
      width: 290px !important;
      height: 100% !important;
    }
  }

  &__content {
    flex: 1 1 auto;
    padding: 1.5rem 1rem;
    overflow: auto;

    &.complete {
      height: 58vh;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.6rem;

    &__actions {
      display: flex;
      align-items: center;

      .toggle-view {
        .active {
          background-color: #fff;
          border-radius: 0.4rem;
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }

  .icon-btn {
    height: 2rem !important;
    width: 2rem !important;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 10rem);
    justify-content: space-between;
    grid-row-gap: 19px;
    grid-column-gap: 30px;
  }

  &__item {
    &__title {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      text-align: center;
      font-weight: 600;
    }

    &__badge {
      position: absolute;
      right: 8px;
      top: 8px;
      // background: #012f54;
      color: white;
      padding: 0.1rem 0.5rem;
      font-size: 0.6rem;
      border-radius: 1rem;
      min-width: 4rem;
      text-align: center;
      z-index: 3;
    }

    &__maj {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0.5rem;
      font-size: small;
      color: white;
      background-color: #001f3173;
      backdrop-filter: blur(5px);
      border-radius: 0.7rem;
      visibility: hidden;
    }

    &__content {
      height: 12rem;
      border-radius: 0.7rem;
      border: 0.1rem solid var(--bs-extra);
      background-color: #fff;
      transition: transform 0.3s ease-in-out;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 0.5rem;
      font-size: smaller;

      &:hover {
        .maj-show {
          visibility: visible;
        }
      }

      &.is-dash {
        border-style: dashed;
      }
    }

    &__thumb {
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;

      > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__done {
    &__title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    &__lable {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    flex: 0 1 auto;
  }

  &__upload {
    left: 0;
    top: 0;
    z-index: 3;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;

    &__btn-close {
      position: absolute;
      right: 0.7rem;
      top: 0.7rem;
      height: 2.5em;
      width: 2.5rem;
      cursor: pointer;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &__content {
      max-width: 34rem;
      width: 100%;
      display: flex;
    }

    &__box {
      background-color: #fff;
      border: 0.8rem solid var(--bs-basic);
      width: 35%;
      min-height: 17rem;
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &.empty {
        position: relative;
        border: 0.8rem solid var(--bs-light);
        background-color: #f8f8f8;
        cursor: pointer;

        .drag-end-drop-box {
          text-align: center;
          color: var(--bs-extra);
          font-weight: 500;
          width: 100%;
          height: 100%;
          border-radius: 0.6rem;
          border: 3px dashed #aaa;
          padding: 0 0.3rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &__square {
            width: 8rem;
            height: 8rem;
            display: flex;
            justify-content: center;
            align-items: center;

            > img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    &__description {
      width: 55%;
      display: flex;
      flex-direction: column;

      &__header {
        margin-bottom: 1.1rem;
        flex: 0 1 auto;

        &__type {
          padding-left: 0.2rem;
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;
        }

        &__link {
          color: #269999;
          text-decoration: underline;
          cursor: pointer;
        }

        &__title {
          padding-top: 0.4rem;
          font-size: 1.1rem;
          color: var(--bs-dark);
          font-weight: bold;
        }
      }

      &__desc {
        flex: 1 1 auto;

        &__title {
          margin-bottom: 0;
          margin-left: 0;
          font-size: 1.1rem;
          color: var(--bs-dark);
          font-weight: bold;
        }

        &__content {
          padding-left: 0.7rem;
        }
      }

      &__footer {
        margin-top: 1rem;
        display: flex;
        align-items: flex-end;
        flex: 1 1 auto;

        .circle {
          width: 2.2rem;
          height: 2.2rem;
          background-color: var(--bs-accent);
          border-radius: 50%;
          flex: none;
          margin-right: 0.8rem;
          position: relative;
          cursor: pointer;

          &-green {
            background-color: #249999;
          }

          &--red {
            background-color: rgb(194, 75, 75);
            color: #fff;
          }

          svg {
            left: 50%;
            top: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          }

          &.invisible {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }
  }

  &__edit {
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    min-height: 600px;
    position: absolute;
    background-color: #fff;
    color: var(--bs-extra);
    padding: 1.3rem;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex: 0 1 auto;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
    }

    &__content {
      flex: 1 1 auto;
      padding-left: 1.4rem;
      padding-right: 1.4rem;
    }

    &__btn-close {
      height: 2.5em;
      width: 2.5rem;
      cursor: pointer;
      text-align: end;
      flex: 0 1 15%;
    }

    &__title {
      font-size: 1rem;
      font-weight: 600;
      flex: 1 1 auto;
    }

    &__field {
      margin-bottom: 1rem;

      &__label {
        font-size: 1rem !important;
        font-weight: 600;
        margin-right: 1rem;
      }

      &__input {
        select {
          border-radius: 3rem !important;
          font-size: 1rem;
          padding: 0.5rem 2.4rem 0.5rem 0.8rem;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      padding: 1rem;
    }
  }

  .rounded-lg {
    border-radius: 12rem !important;
  }
}

.disabled--griser {
  opacity: 0.6;
  pointer-events: none;
  cursor: none;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: var(--bs-extra) !important;
}

.disabled--griser {
  opacity: 0.6;
  pointer-events: none;
  cursor: none;
}

.relative {
  position: relative;
}

.absolute-full {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.add-doc {
  flex: 1 1 auto;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__content {
    display: flex;
    justify-content: center;
    align-items: stretch;
    column-gap: 3rem;

    .left {
      flex: 0 1 40%;
      display: flex;
      flex-direction: column;

      > .box {
        cursor: pointer;
        height: 12rem;
        border: #249999 2px solid;
        background-color: #eee;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        > span.icon-new-doc {
          background-color: #1a468e;
          width: 60px !important;
          height: 60px !important;
          border-radius: 100%;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          > svg {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }

      > div.box-title {
        color: var(--bs-dark);
        font-weight: 600;
        margin-bottom: 0.8rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
      padding-top: 35px;

      > .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
        width: 100%;
        column-gap: 1rem;

        > label {
          flex: 1 1 auto;
          font-weight: 600;
          color: var(--bs-extra);
        }

        > input {
          border-color: #249999;
          background-color: #eee;
          flex: 0 1 auto;

          &:focus {
            background-color: #fff;
          }

          &.w-s {
            width: 68% !important;
          }
        }
      }
    }
  }
}

.add-document {
  width: 100% !important;
  height: 100% !important;
  padding: 0 1rem;

  &__header {
    width: 100% !important;
    flex-direction: row !important;
    align-items: center !important;
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--bs-extra);
    color: var(--bs-extra);

    svg {
      cursor: pointer;
    }
  }
}

.modal-kyc {
  padding-top: 200px;

  &__body {
    background-color: #fff;
    padding: 1.4rem 3rem;
    border-radius: 1rem;
    // max-width: 600px !important;
    padding-bottom: 0 !important;
  }

  &__title {
    text-align: center;
    font-weight: bold;
    position: relative;
    margin-bottom: 2px;

    &::after {
      position: absolute;
      height: 1.1px;
      background-color: #ccc;
      content: '';
      width: 100%;
      display: block;
      top: 160%;
    }
  }

  &__form {
    margin-top: 2rem;

    textarea {
      width: 100% !important;
      border-radius: 0.3rem;
      background-color: #eee;
      border-color: #ccc;
      border-width: 1.6px;
      padding: 0.7rem 0.5rem;

      &:focus {
        background-color: #fff;
        border-color: #cccc;
        outline: none;
      }
    }
  }

  &__footer {
    margin-top: 1.4rem;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.bo-list-box {
  width: 100% !important;
  height: 100% !important;
  padding: 0 1rem;

  &__header {
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--bs-extra);
    color: var(--bs-extra);
  }
}

.label-text {
  max-width: 95%;
}

.form-input {
  input {
    width: 100% !important;
    padding: 0.5rem 1rem !important;
    border: none;
    border-radius: 0.5rem !important;
    border-width: 2px !important;
    border-color: #ced4da !important ;
    background: rgba(128, 128, 128, 0.0784313725);
    transition: 0.2s;
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      outline: none;
      border-color: #08ffa0 !important;
      background-color: #fff;
      box-shadow: 0 0 0 0.25rem rgba(8, 255, 160, 0.25);
    }
    &.w-80 {
      width: 80% !important;
    }
  }
}

.users-list__table {
  overflow-y: auto;

  &:not(.table) {
    height: 50vh;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    box-shadow: inset 0 -2px 0 0 var(--bs-extra);
  }
}
