@media (max-width: 575.98px) {
  // XS

  .hide-xs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  // SM

  .hide-xs {
    display: none;
  }

  .hide-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  // MD

  .hide-xs {
    display: none;
  }

  .hide-sm {
    display: none;
  }

  .hide-md {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  // LG

  .hide-xs {
    display: none;
  }

  .hide-sm {
    display: none;
  }

  .hide-md {
    display: none;
  }

  .hide-lg {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  // XL

  .hide-xs {
    display: none;
  }

  .hide-sm {
    display: none;
  }

  .hide-md {
    display: none;
  }

  .hide-lg {
    display: none;
  }

  .hide-xl {
    display: none;
  }
}

@media (min-width: 1400px) {
  //XXL

  .hide-xs {
    display: none;
  }

  .hide-sm {
    display: none;
  }

  .hide-md {
    display: none;
  }

  .hide-lg {
    display: none;
  }

  .hide-xl {
    display: none;
  }

  .hide-xxl {
    display: none;
  }
}
