.souscriptions-stepper-dossier {
    &__item {
        &__title {
            margin-top: 0.4rem;
            .upload-document-type {
                width: 100%;
                padding: 0.4rem 0.8rem !important;
                background: #80808014;
            }
        }
    }
}
