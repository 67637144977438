.forgot-password {
    width: 100%;
}

.forgot-password__form__forgot-password-link:hover {
    text-decoration: underline;
}

// @media (max-width: 767.98px) {
//     .forgot-password__form {
//         padding: 6rem 0;
//     }
// }

@media (min-width: 1199.98px) {
    .forgot-password__form {
        width: 30%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .forgot-password__form {
        width: 55%;
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .forgot-password__form {
        width: 40%;
    }
}