.form-select-custom__icon {
    position: absolute;
    top     : .6rem;
    right   : .4rem;
}

.form-select-custom__control {
    padding-right: 2rem;
}

.form-select {
    padding: 0.5rem 1rem !important;
}