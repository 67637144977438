.home {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__content {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .__title {
            letter-spacing: 0.15rem;
        }
    }

    &__header {
        flex: 0 1 auto;
    }
}
