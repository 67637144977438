.form-input__icon {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
}

.form-input__icon-sm {
    top: 0.15rem;
}

.form-input__control {
    // padding-right: 2rem;
}

.euro {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
