.input-doc {
  border: 1.3px dashed #1a468e63;
  padding: 0.4rem 1rem;
  border-radius: 0.25rem;
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 0.4rem 0;
  width: 100%;

  &.w-full {
    width: 100% !important;
  }
  cursor: pointer;

  &__icon {
    margin-right: 1rem;
  }

  &__label {
    span:first-child {
      font-size: 0.85rem;
      font-weight: 600;
      color: #1a468e;
    }
  }

  input {
    width: 100% !important;
    padding: 0.5rem 1rem !important;
    border-radius: 0.25rem;
    border: none;
    border: 1px solid gray;
    &:focus {
      outline: none;
      border-color: #08ffa0;
    }
    &.w-80 {
      width: 80% !important;
    }
  }

  // &.active,
  // &:hover {
  //   // background-color: #1a468e;
  //   color: #fff !important;
  //   * {
  //     color: #fff !important;
  //   }
  // }
}
