.app {
    min-height: 100vh;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: 8rem;
    background-repeat: no-repeat;
    background-position: top 5rem left -1.2rem, bottom 1rem right -1.2rem;
    background-image: url(assets/images/background/left-bg.svg), url(assets/images/background/right-bg.svg);

    &__content {
        flex: 1 1 auto;
        display: flex;
    }

    .footer {
        margin-top: 2.5rem;
        flex: 0 1 20%;
        position: relative;
        overflow: hidden;
        padding: 1.5rem 0 0 0;
        z-index: -5;
        &__background {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
