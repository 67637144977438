.login {
    width: 100%;

    &__form__forgot-password-link:hover {
        text-decoration: underline;
    }
}

// @media (max-width: 767.98px) {
//     .login__form {
//         padding: 6rem 0;
//     }
// }

@media (min-width: 1199.98px) {
    .login__form {
        width: 25%;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .login__form {
        width: 55%;
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .login__form {
        width: 35%;
    }
}