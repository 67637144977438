@import '@assets/scss/bootstrap/variables';

.form-component {
  padding: 0 1rem;
  width: 100% !important;

  &__header {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    font-size: 1.1rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid var(--bs-extra);
    color: var(--bs-extra);

    &__title {
      flex: 80% 1 0;
    }

    &__icon {
      text-align: end;
      flex: auto 1 1;
    }

    svg {
      cursor: pointer;
    }
  }

  &__important-label {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    font-size: 1.05rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    color: var(--bs-extra);
  }

  &__content {
    padding-top: 1.5rem;
    // padding-right: 6rem;
  }

  &__footer {
    padding-top: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__question-field {
    &--50 {
      width: 50% !important;
    }

    margin-bottom: 1rem;

    .label {
      margin-bottom: 0.4rem;
    }

    .input-text {
      width: 50% !important;
      display: block !important;
    }

    &--50 {
      width: 50% !important;

      input[type='text'] {
        width: 100% !important;
        background-color: #eee;

        &:hover {
          box-shadow: none !important;
          background-color: #eee;
        }

        &:focus {
          border-color: initial !important;
          box-shadow: none !important;
          border-color: #ccc !important;
          background-color: #fff !important;
        }
      }
    }

    .input-checkbox {
      width: auto !important;
      height: auto !important;
    }

    .input-select {
      padding: 0.5rem 1rem !important;
      // font-size: 19px !important;
      width: 50%;

      option {
        font-size: 19px !important;
      }
    }

    .input-comment {
      width: 100%;
      border-radius: 0.3rem;
      background-color: #eee;
      border-width: 2px;
      border-color: #ccc;
      padding: 0.3rem;

      &:focus {
        border-color: #ccc !important;
        background-color: #fff;
        outline: none !important;
      }
    }

    .checkbox-div {
      padding-top: 1rem;
      padding-left: 2rem;

      &--checkbox {
        padding-left: 0;
      }
    }

    .checkbox-field {
      label {
        display: flex !important;
        justify-content: flex-start;
        align-items: flex-start;

        span:last-child {
          margin-left: 1rem;
        }
      }

      .box {
        cursor: pointer;
        width: 20px !important;
        height: 20px !important;
        border: #269999 1.3px solid;
        border-radius: 3px;
        display: inline-block;
        flex: none;
        margin: 0 !important;
        overflow: hidden;

        &--radio {
          border-width: 3px;
          border-radius: 50%;
          border-color: #ccc;
        }
      }

      .input-box:checked ~ .box,
      .input-box.active ~ .box {
        background-color: #269999;
        border-color: transparent !important;

        &--radio {
          border-color: #269999b9 !important;
          background-color: #269999 !important;
        }

        &--checkbox {
          background-color: transparent !important;
          border-color: #269999 !important;

          svg {
            display: block;
          }
        }
      }

      .input-box:disabled ~ .box {
        cursor: unset;
      }

      label {
        display: inline-block;
        // margin-left: 10px;
      }
    }

    // .box--checkbox {
    //   width: 20px !important;
    //   height: 20px !important;
    //   border-radius: 3px;
    //   flex: initial;
    //   border: #269999 2px solid !important;
    //   display: inline-flex !important;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   svg {
    //     fill: #269999 !important;
    //     // transform: translate(2px,-5px);
    //     display: none;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //   }
    // }
    &--100 {
      width: 100% !important;

      .multi-select {
        max-width: 375px !important;
      }

      label,
      span {
        // background-color: red !important;
        width: 100% !important;
        text-align: left !important;
      }

      .dropdown {
        border-width: 2px !important;
        border-color: #ccc !important;
        background-color: #eee !important;
      }

      .countries-box {
        &__content {
          background-color: #eee;
        }
      }
    }
  }
}

.form-kyc-table {
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  table {
    width: 100%;

    td:first-child {
      padding-left: 10px;
    }

    thead {
      tr {
        background-color: lightgray;
      }
    }
  }

  .item {
    height: 40px;

    .label {
      width: 15px;
      height: 15px;
      box-sizing: content-box;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }

    .radio {
      min-width: 60px;
      text-align: center;
    }
  }
}
