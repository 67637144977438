.entite-form {
  &--pt-sm {
    & .title {
      margin-bottom: 1rem !important;
    }
  }

  &__container {
    padding-top: 5rem;
  }

  &__form {
    padding: 0.5rem 5rem !important;
    min-height: 500px !important;

    .header-top {
      font-size: 1rem;
      border-bottom: 2px solid var(--bs-extra);
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }

  .add-signataire-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .content {
    width: 100% !important;
  }

  .btns {
    margin-top: 2rem;
    button {
      &:first-child {
        color: var(--bs-extra);
        border: 1px solid var(--bs-extra);

        :hover {
          color: #fff;
          background-color: var(--bs-extra);
        }
      }
      margin: 0 !important;
      text-transform: capitalize !important;
      border-radius: 0.6rem;
      padding-left: 2.2rem !important;
      padding-right: 2.2rem !important;
      font-size: 0.8rem;
      font-weight: 400 !important;
    }
  }

  select,
  input {
    border-color: #08ffa0 !important;
    border-width: 1.5px !important;
    background-color: rgba(238, 238, 238, 0.347);
  }

  & .phone-number-div {
    input {
      width: 100% !important;
      // padding: 1.4rem 4rem !important;
      padding: 1.2rem 4rem !important;
      border-color: #ccc !important;
      &:hover {
        border-color: #08ffa0 !important;
      }
    }
    .react-tel-input .flag-dropdown {
      position: absolute;
      top: 0;
      bottom: 0;
      padding: 0 0.3rem;
      background-color: #0cffa08c;
      border: 1px solid #cacaca00;
      border-radius: 3px 0 0 3px;
    }
  }

  input.form-control,
  select.form-control {
    border-color: #ccc !important;
    &:hover:not(:disabled) {
      border-color: #08ffa0 !important;
    }
  }
}

.edit-step-1,
.step1-show-all {
  .field {
    .box {
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
      border: #269999 1.3px solid;
      border-radius: 3px;
      display: inline-block;
      flex: none;
      margin: 0 !important;
      overflow: hidden;

      &--radio {
        border-width: 3px;
        border-radius: 50%;
        border-color: #ccc;
      }
    }

    & input:checked ~ .box,
    & input.checked ~ .box {
      background-color: #269999;
      border-color: #269999;
    }
  }
}

.step1 {
  height: 55vh;
}
