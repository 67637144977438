.entite-form {
  &__container {
    padding-top: 5rem;
    max-width: 90%;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__form {
    padding: 2rem;
  }

  &__top {
    flex: 1 1 auto;
  }

  &__bottom {
    flex: 0 1 auto;
    padding: 1rem 2rem;
  }

  .add-signataire-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .content {
    width: 70%;
    margin: 0 auto;
  }

  // .btns {
  //     position: absolute;
  //     bottom: 2rem;
  //     right: 2rem;
  // }

  select,
  input {
    border-color: #08ffa0 !important;
    border-width: 1.5px !important;
    background-color: rgba(238, 238, 238, 0.347);
  }
}
