.user-details__container {
  padding-top: 5rem;
}

.user-details__form__cni__container {
  height: 400px;
  width: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
}

.user-details__form__cni__box {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-details__form__cni__box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-details__form__cni__box::before {
  font-family: 'Font Awesome 5 Free';
  content: '\f2bb';
  font-weight: 900;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-extra);
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #142f542b;
  transition: all 0.15s linear;
}

// @media (min-width: 768px) and (max-width: 991.98px) {
//     .user-details__container {
//         .card {
//             .card-body {
//                 padding: 3rem 4rem;
//             }
//         }
//     }
// }

// @media (min-width: 991.98px) and (max-width: 1199.98px) {
//     .user-details__container {
//         .card {
//             .card-body {
//                 padding: 3rem 5rem;
//             }
//         }
//     }
// }

// @media (min-width: 1200px) {
//     .user-details__container {
//         .card {
//             .card-body {
//                 padding: 4rem 10rem;
//             }
//         }
//     }
// }
