body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    // background-color: var(--bs-accent);
  }

  &::-webkit-scrollbar {
    width: 15px;
    background-color: #f5f5f5;
    // background-color: var(--bs-accent);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-accent);
    // background-color: #f5f5f5;
  }
}

.input {
  &-radio {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    border: #269999 1.3px solid;
    border-radius: 3px;
    display: inline-block;
    flex: none;
    margin: 0 !important;
    overflow: hidden;
    border-radius: 50%;
  }
}

input {
  ~ .input-radio {
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    border: #269999 1.3px solid;
    border-radius: 3px;
    display: inline-block;
    flex: none;
    margin: 0 !important;
    overflow: hidden;
    border-radius: 50%;
    cursor: pointer;
  }

  &:checked ~ .input-radio {
    background-color: #269999;
    border-color: #269999;
  }
}
