.kyc-folder-document-category-title__icon {
    border-radius: 100%;
    color: white;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.35rem;
    height: 1.35rem;
}

.relative{
    position: relative !important;
    svg{
        position: absolute;
        width: 100%;
    }
}

