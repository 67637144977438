.header {
    position: absolute;
    z-index: 2000;
    width: 100%;
}

.header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.3s ease-in-out;
}

.header__nav__item {
    margin: 0 1.5rem;

    a.header__nav__item__link {
        text-decoration: none;

        &:hover {
            color: var(--bs-accent);
            border-bottom: 3px solid var(--bs-accent);
        }

        &.active {
            color: var(--bs-accent);
            border-bottom: 3px solid var(--bs-accent);
        }
    }
}

.header__nav__item__link {
    font-weight: 600;
    color: var(--bs-extra);
    padding-bottom: 0.2rem;
    border-bottom: 3px solid var(--bs-white);
}

.header__nav__item__badge {
    height: 0.8rem;
    width: 0.8rem;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    font-weight: 600;
}

.header__nav__menu {
    position: relative;
}

.header__nav__menu__container {
    position: absolute;
    background: var(--bs-accent);
    top: 4rem;
    right: 0;
    min-width: 12rem;
    z-index: 5;

    &.header__nav__profile__container {
        min-width: 15rem;
    }
}

.header__nav__menu__item {
    padding: 0.7rem;
    color: var(--bs-white);
    text-decoration: none;
    font-weight: 600;
    width: 100%;

    &:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    &:nth-child(even) {
        border-bottom: 0.1rem solid var(--bs-white);
        border-top: 0.1rem solid var(--bs-white);
    }

    &:hover {
        background: var(--bs-white);
        color: var(--bs-accent);
    }

    &.active {
        background: var(--bs-white);
        color: var(--bs-accent);
    }
}

.header__nav__menu__item__badge {
    height: 0.6rem;
    width: 0.6rem;
    padding: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    font-weight: 600;
}

.avatar {
    border-radius: 50%;
    border: 3px solid var(--bs-accent);
    height: 2.2rem;
    width: 2.2rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar__image {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

.language-dropdown {
    &__toggle {
        padding: 0;
        border-radius: 20px !important;
        background-color: var(--bs-light);
        border: 2px solid var(--bs-accent);
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 2rem;
    }

    &__menu {
        border-radius: 5px !important;
        background-color: var(--bs-light);
        border: 1px solid var(--bs-accent);
        max-width: 5em !important;
        
        &__wrapper {
            max-width: 5em !important;
            background-color: var(--bs-light);
            border-radius: 5px !important;
        }
    }
}

.dropdown-menu {
    max-width: 2rem;
}