.origine-section-form  {
  position: relative;
  width: 50% !important;
  padding: .4rem 1rem;
  .upload-document-type {
    width: 30%;
    padding: 0.4rem 0.8rem !important;
    background: #80808014;
  }

  .upload-document-btn {
    padding: 0.5rem;
    border: 1px dashed var(--bs-extra);
    border-radius: 0.2rem;
    cursor: pointer;
    width: 100%;

    &:hover {
      color: white !important;
    }
  }

  .btns{
    position: absolute;
    bottom: 4rem;
    width: 154.1% !important;
    left: 0 !important;
    right: 0 !important;
  }
}

.new-doc-origine-input{
  width: 100% !important;
}

.header-doc{
  // width: 100.1% !important;
}