.form-input__icon {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
}

.react-tel-input .form-control {
    height: auto !important;
    line-height: normal !important;
    padding: 0.7rem 0.8rem 0.7rem 48px !important;
}

.form-control__control__sm  {
    padding: .4rem .5rem .4rem 3rem !important
}

.form-input__control__password {
    background-image: none !important;
}

.border-none {
    border-width: 0px !important;
}

.is-invalid {
    border-width: 2px !important;
}

.react-tel-input .flag-dropdown {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}

.react-tel-input .selected-flag {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}
