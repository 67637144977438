.investissement-item {
    border-radius: 0.8rem;
    padding: 0.6rem;
    margin-bottom: 0.6rem !important;
}

.investissement-item__progress {
    height: 0.6rem !important;
}

.investissement-item__details {
    font-size: 0.75rem;
}

.investissement-item__title {
    font-size: 0.9rem;
}

@media (max-width: 1199.98px) {
    .investissement-item {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .investissement-item {
        max-width: 63rem !important;
    }
}
