.kyc-folder-document-item {
  display: flex;
  flex: 0 1 45%;
  flex-wrap: wrap;

  &__filename {
    flex-grow: 1;
    position: relative;

    &:not(.form):hover {
      text-shadow: 0 0 5px #001f3173;
      color: transparent;
      background-color: #001f3173;
      backdrop-filter: blur(50px);

      .kyc-folder-document-item__date {
        visibility: visible;
      }
    }
  }

  .wrapper {
    width: 100%;
  }

  &__date {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0.2rem;
    color: #fff;
    visibility: hidden;
    background-color: #001f3173;
    z-index: 2;
    transition: all 0.05s ease-in-out;
  }

  &.cancel {
    .kyc-folder-document-item__filename {
      border: 1px solid #ff003d85;
    }
  }

  .warn {
    width: 100%;
    padding: 0.3rem;
    color: var(--bs-dark) !important;
  }

  &__button {
    flex: 0 1 45%;
    display: flex;

    & > .upload-document-btn {
      flex: 0 1 100%;
      padding: 0.5rem;
      border: 1px dashed var(--bs-extra);
      border-radius: 0.2rem;
      cursor: pointer;
      transition: 0.1s ease-in-out;

      &:hover {
        color: white !important;
        background-color: #002f54;
      }
    }

    &__others {
      flex: 0 1 15%;
    }
  }

  .tip {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.op-7 {
  opacity: 0.7;
}
