.double-authenticate {
    width: 100%;
}

.double-authenticate__form__forgot-password-link:hover {
    text-decoration: underline;
}

// @media (max-width: 767.98px) {
//     .double-authenticate__form {
//         padding: 6rem 0;
//     }
// }

@media (min-width: 768px) and (max-width: 991.98px) {
    .double-authenticate__form {
        width: 55%;
    }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
    .double-authenticate__form {
        width: 40%;
    }
}

@media (min-width: 1200px) {
    .double-authenticate__form {
        width: 30%;
    }
}

.link-m{
    font-size: .8rem;
    text-decoration: none;
    &:hover{
        text-decoration: none !important;
    }
    &__label{
        text-decoration: none !important;
        display: inline-block;
        &:hover{
            text-decoration: underline !important;
        }
    }
    &__mail{
        text-decoration: underline;
    }
   
}