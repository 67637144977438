.souscriptions-stepper-configuration {
  // padding: 3rem 5rem;

  &__wrapper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    height: 100%;
    padding: 0 2rem;

    .row {
      width: 100%;
    }

    & .-tranlate-x-20 {
      transform: translateX(-50px);
    }
  }

  .card-body {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header-top {
    font-size: 1.05rem;
    padding-bottom: 2rem;
    position: relative;
    width: 100%;
    &::after {
      content: '';
      height: 2px;
      background-color: #1a468e;
      position: absolute;
      top: 77%;
      left: 0;
      right: 0;
      opacity: 0.4;
    }
  }

  .field {
    .box {
      cursor: pointer;
      width: 20px !important;
      height: 20px !important;
      border: #269999 1.3px solid;
      border-radius: 3px;
      display: inline-block;
      flex: none;
      margin: 0 !important;
      overflow: hidden;

      &--radio {
        border-width: 3px;
        border-radius: 50%;
        border-color: #ccc;
      }
    }

    & input:checked ~ .box,
    & input.checked ~ .box {
      background-color: #269999;
      border-color: #269999;
    }
  }

  &__content {
    flex: 1 1 auto;
    margin: 4rem 0;
  }

  &__thanks {
    flex: 1 1 auto;
    height: 50vh;
  }

  &__done {
    &__title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &__lable {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  &__fieldset {
    border: 2px solid #142f54;
    border-radius: 0.7rem;
    padding: 2rem 2rem 1rem 2rem;
    width: 100%;

    &__legend {
      width: auto;
      float: none;
      margin: 0 auto;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0 1.5rem;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    flex: 0 1 auto;
  }

  &__questions {
    flex: 1 1 auto;
    width: 100%;
    margin: 1.5rem 0;

    .required {
      border: none;
      width: 1px;
      height: 1px;
      position: absolute;
    }
  }

  &__buttons {
    width: 100%;
    flex: 0 1 auto;
  }

  .btn-configuration-step {
    text-transform: capitalize !important;
    padding-left: 2.2rem !important;
    padding-right: 2.2rem !important;
    border-radius: 0.6rem;
    font-size: 0.8rem;
  }

  &__step2short {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10vh 0;
  }
}

@media (max-width: 991.98px) {
  .souscriptions-stepper-configuration {
    &__title {
      width: 100%;
    }

    &__content {
      min-width: 100%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .souscriptions-stepper-configuration {
    &__title {
      width: 70%;
    }

    &__content {
      min-width: 50%;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .souscriptions-stepper-configuration {
    &__title {
      width: 70%;
    }

    &__content {
      min-width: 45%;
      max-width: 50%;
    }
  }
}

@media (min-width: 1400px) {
  .souscriptions-stepper-configuration {
    &__title {
      width: 70%;
    }

    &__content {
      min-width: 40%;
    }
  }
}

.disabled {
  opacity: 0 !important;
}

.form-control:hover {
  &:not(:disabled) {
    color: #002f54;
    background-color: #ffffff;
    border-color: #84ffd0;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(8 255 160 / 25%);
  }
}
