@import "./variables";

@import "~bootstrap/scss/bootstrap";

.btn {
    font-weight: 700 !important;
    padding: 0.45rem 1.5rem !important;
    text-transform: uppercase !important;
}

.btn-icon {
    padding: 0.4rem !important;
    border-radius: 50% !important;
    height: 2.5rem !important;
    width: 2.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    path {
        stroke: currentColor;
    }
}

.btn-icon-sm {
    padding: 0.2rem !important;
    height: 1.4rem !important;
    width: 1.4rem !important;
}

.btn-primary {
    color: var(--bs-extra) !important;
}

.btn-primary-second {
    color: #fff !important;
    background-color: map-get($custom-colors, basic);
    opacity: 1 !important;
    border-color: map-get($custom-colors, basic) !important;
}

.fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.fs-7 {
    font-size: 0.8rem !important;
}

.fs-8 {
    font-size: 0.75rem !important;
}

.fs-9 {
    font-size: 0.7rem !important;
}

.fs-10 {
    font-size: 0.65rem !important;
}

.fs-11 {
    font-size: 0.6rem !important;
}

.fs-12 {
    font-size: 0.55rem !important;
}

.fs-13 {
    font-size: 0.5rem !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.no-cursor-pointer {
    cursor: default !important;
}

.card.rounded {
    border-radius: 0.8rem !important;
}

.form-control {
    border-radius: 0.5rem !important;
    border-width: 2px !important;
}

.mt-8{
    margin-top: 3.9rem !important;
}

.first-letter-upper::first-letter{
    text-transform: uppercase !important;
}

.w-60{
    min-width: 40% !important;
}

.form-input__icon{
    transform: translateY(-50%) !important;
    top: 50% !important;
}
