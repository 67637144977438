.initier-souscription-item {
    border-radius: 0.8rem;
    padding: 0.6rem;
    margin-bottom: 0.6rem !important;
}

.initier-souscription-item__progress {
    height: 0.6rem !important;
}

.initier-souscription-item__details {
    font-size: 0.85rem;
    max-width: 500px;
}

.initier-souscription-item__title {
    font-size: 0.9rem;
}

@media (max-width: 1199.98px) {
    .initier-souscription-item {
        text-align: center;
    }
}

@media (min-width: 1200px) {
    .initier-souscription-item {
        padding: 0.8rem 0.6rem;
        max-width: 63rem !important;
    }
}
