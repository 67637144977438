.data-not-found-item {
    border-radius: 0.8rem;
    padding: 0.6rem;
    max-width: 63rem;
    margin-bottom: 0.6rem !important;
}

@media (min-width: 1400px) {
    .data-not-found-item {
        max-width: 63rem !important;
    }
}