@import '@assets/scss/bootstrap/variables';

.tooltip > .tooltip-inner {
  background-color: $body-bg;
  border: 1px solid $body-bg;

  * {
    margin: 0;
    padding: 0;
  }
}

.tooltip > .tooltip-arrow::before {
  border-top-color: $body-bg;
}
