.space {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &__content {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    padding-top: 1rem;

    & > div {
      width: 100%;
    }
  }

  &__header {
    flex: 0 1 auto;
  }
}

.space {
  position: relative;
}

.space__container {
  padding: 3rem 0 0 0;
}

.space__buttons__document {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0.7rem;
  background-color: var(--bs-accent);
  bottom: 6rem;
  left: 0;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
  transition: all 5s ease-in-out;

  &:hover .space__buttons__document__text {
    display: block;
  }
}

.space__buttons__help {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 0.7rem;
  background-color: var(--bs-accent);
  bottom: 2rem;
  left: 0;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  cursor: pointer;
  transition: all 5s ease-in-out;

  &:hover .space__buttons__help__text {
    display: block;
  }
}

.space__buttons__document__text,
.space__buttons__help__text {
  display: none;
  transition: all 5s ease-in-out;
}

.help-contact-modal {
  border-radius: 1rem !important;
  overflow: hidden !important;
}

.help-contact-modal__image {
  border-radius: 50%;
  border: 3px solid var(--bs-accent);
  height: 8rem;
  width: 8rem;
  padding: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.help-modal {
  .btn.btn-primary {
    background-color: #269999 !important;
    color: #fff !important;
    border-color: #269999 !important;
  }
}
