$primary: #08ffa0;
$secondary: #f5f2f3;
$info: #0088ff;
$danger: #ff124b;  //ff124b
$success: #198754;
$warning: #ffc107;
$light: #f8f9fa;
$dark: #001f31;
$violet: #ee82eea8;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$custom-colors: (
  "classic": #0064b2,
  "extra": #002f54,
  "accent": #1a468e,
  "basic": #269999,
  "duck": #006286,
  "violet": $violet
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$body-bg: #002f54;
$body-color: #ffffff;

$font-family-sans-serif: "Montserrat", sans-serif;

$input-btn-padding-y: 0.7rem !default;
$input-btn-padding-x: 0.8rem !default;
$input-btn-font-size: "14px" !default;

$input-bg: $body-color;
$input-color: $body-bg;
$input-height-border: 2;
$input-font-weight: 600;
$form-label-font-weight: 600;

$font-size-base: 0.85rem;

$card-color: $body-bg;

$table-color: $body-bg;
$table-cell-padding-y: 0.8rem;
$table-cell-padding-x: 0.5rem;
$table-cell-padding-y-sm: 0.35rem;
$table-cell-padding-x-sm: 0.25rem;

$width-card : 97%;
$max-width-card : 1120px;

// mixins

@mixin flex($jc : space-between,$ai : center,$fd: row) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
  flex-direction: $fd;
  @content;
}

@mixin btn($p: static,$bg : map-get($custom-colors, accent), $co : #fff) {
  padding: .47rem 2.5rem;
  cursor: pointer;
  position: $p;
  right: 1rem;
  bottom: 1rem;
  background-color: $bg; 
  color: $co;
  border-radius: .3rem;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  @content;
  text-align: center;
}

@mixin center-with-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  @content;
}