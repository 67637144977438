.souscriptions-stepper-validated {
    min-height: 58vh;

    &__wrapper {
        display: flex !important;
        flex-direction: column !important;
        align-items: center;
    }

    &__popover {
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        z-index: 2;
    }

    &__content {
        flex: 1 1 auto;
        padding: 1.5rem 5rem;
        max-height: 75vh;
        overflow: auto;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            // border-radius: 10px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
            width: 12px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: var(--bs-accent);
        }
    }

    &__document {
        text-align: center;

        &__title {
            font-size: 0.9rem;
            margin-bottom: 1rem;
        }

        &__preview {
            width: 7rem;
            height: 10rem;
            border-radius: 0.5rem;
            border: 0.3rem solid var(--bs-accent);
            position: relative;
            margin: auto;
            cursor: pointer;
            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            &__icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &__done {
        &__title {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }
        &__lable {
            font-size: 1.2rem;
            font-weight: 500;
        }

        &__termes {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            justify-items: center;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 1.1rem;
        text-align: center;
        flex: 0 1 auto;
    }
}
