.user-form__container {
    min-height: 80vh;
    padding-top: 5rem;
}

.user-form__form__cni__container {
    height: 21vh;
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
}

.user-form__form__cni__box {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-form__form__cni__box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-form__form__cni__box.card::before {
    font-family: "Font Awesome 5 Free";
    content: "\f2bb";
    font-weight: 900;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-extra);
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #142f542b;
    transition: all 0.15s linear;
}

.user-form__form__cni__box.done::before {
    font-family: "Font Awesome 5 Free";
    content: "\f058";
    font-weight: 900;
    font-size: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-extra);
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #142f542b;
    transition: all 0.15s linear;
}

.user-form__form__cni__box:hover::before {
    background-color: #142f5448;
}

.user-form__form__cni__box.upload:hover::before {
    font-family: "Font Awesome 5 Free";
    content: "\f382";
    font-weight: 900;
}

// @media (min-width: 768px) and (max-width: 991.98px) {
//     .user-form__container {
//         .card {
//             .card-body {
//                 padding: 3rem 4rem;
//             }
//         }
//     }
// }

// @media (min-width: 991.98px) and (max-width: 1199.98px) {
//     .user-form__container {
//         .card {
//             .card-body {
//                 padding: 3rem 5rem;
//             }
//         }
//     }
// }

// @media (min-width: 1200px) {
//     .user-form__container {
//         .card {
//             .card-body {
//                 padding: 4rem 10rem;
//             }
//         }
//     }
// }

.phone-number-div{
    input{
        width: 100% !important;
        padding: 1.4rem 4rem !important;
    }
    .react-tel-input .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0 0.3rem;
        background-color: #0cffa08c;
        border: 1px solid #cacaca00;
        border-radius: 5px 0 0 5px !important;
    }

    &.input{
        width: 100% !important;
        padding: 1.4rem 4rem !important;
    }
    &.react-tel-input .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0 0.3rem;
        background-color: #0cffa08c;
        border: 1px solid #cacaca00;
        border-radius: 3px 0 0 3px;
    }
 }