.box {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  & #card-body {
    padding: 1rem !important;
  }
}
.box__container.card {
  height: 100%;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .box__container {
    &.card {
      .card-body {
        padding: 2.5rem 4rem;
      }
    }
  }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .box__container {
    &.card {
      .card-body {
        padding: 2.5rem 5rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .box__container {
    &.card {
      .card-body {
        padding: 2.5rem 10rem;
      }
    }
  }
}
