.content-grid {
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 50px;
  height: 48vh;
  overflow-y: auto;
}

.souscriptions-stepper-signature {
  padding: 10px;

  &__wrapper {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;

    .simplebar-content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__popover {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    z-index: 2;
  }

  &__header {
    flex: 0 1 auto;
    margin-bottom: 20px;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__check {
    width: 3rem;
    height: 3rem;
    background-color: var(--bs-basic);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &__title {
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    flex: 0 1 auto;
  }

  &__downloads {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__link {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;

      &__icon {
        height: 2rem !important;
        width: 2rem !important;
        margin-left: 1rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__document {
    text-align: center;
    width: 20%;
    min-width: 150px;

    &__title {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    &__preview {
      width: 7rem;
      height: 10rem;
      border-radius: 0.5rem;
      border: 0.3rem solid var(--bs-accent);
      position: relative;
      margin: auto;
      cursor: pointer;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__footer {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
