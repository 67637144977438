.users-list__container {
  padding-top: 5rem;
  .card {
    height: 100%;
    overflow: hidden;

    .card-body {
      overflow: auto;
    }
  }
}

.users-list__table > :not(caption) > * > * {
  border: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .user-details__container {
    .card {
      .card-body {
        padding: 1rem 2rem !important;
      }
    }
  }
}

@media (min-width: 991.98px) and (max-width: 1199.98px) {
  .users-list__container {
    .card {
      .card-body {
        padding: 1.5rem 2.5rem !important;
      }
    }
  }
}

@media (min-width: 1200px) {
  .users-list__container {
    .card {
      .card-body {
        padding: 2.5rem 5rem !important;
      }
    }
  }
}

.table__td {
  width: 340px !important;
  &--1 {
    width: 340px !important;
  }
  &--2 {
    width: 130px !important;
  }
  &--3 {
    width: 140px !important;
  }
  &--4 {
    width: 150px !important;
  }
}

.users-list {
  th,
  td {
    text-align: left !important;
  }
}
